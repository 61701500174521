import { IconButton, Modal, Slide } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import React, { useState } from "react"
import styled from "styled-components"
import CloseIcon from "@material-ui/icons/Close"
import { device } from "../../constants/breakpoints"

const useStyles = makeStyles(theme => ({
  root: {
    height: 300,
    flexGrow: 1,
    minWidth: 300,
    transform: "translateZ(0)",
    // The position fixed scoping doesn't work in IE 11.
    // Disable this demo to preserve the others.
    "@media all and (-ms-high-contrast: none)": {
      display: "none",
    },
  },
  modal: {
    display: "flex",
    padding: theme.spacing(1),
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 12,
  },
  paper: {
    width: 400,
    backgroundColor: "red",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

const Wrapper = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media ${device.tablet} {
    width: 400px;
  }
`

const Container = styled.div`
  position: relative;
`

const settings = {
  dots: true,
  infinite: false,
  slidesToShow: 4,
  slidesToScroll: 2,
  autoplay: false,
}

const Text = styled.p`
  text-align: center;
  margin-bottom: 5px;
  margin-top: 5px;
  font-weight: bold;
  color: ${({ theme }) => theme.midblue};
`
const Image = styled.img`
  object-fit: cover;
  min-height: 500px;
  @media ${device.tablet} {
    height: auto;
    min-height: 200px;
    width: 300px;
  }
`

const ArrowWrapper = styled.div`
  visibility: ${({ hide }) => (hide ? "hidden" : "inherit")};
  cursor: pointer;
  flex: 1;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin: auto;
  height: 100%;
  padding: 20px;
  @media ${device.tablet} {
    padding: 10px;
  }
`

const CloseIconWrapper = styled(IconButton)`
  background: white !important;
  position: absolute !important;
  top: -10px;
  padding: 5px !important;
  right: -10px;
`

const ArrowRight = styled.div`
  float: right;
  width: 0;
  height: 0;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;

  border-left: 30px solid white;
  @media ${device.tablet} {
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;

    border-left: 15px solid white;
  }
`

const ArrowLeft = styled.div`
  width: 0;
  height: 0;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
  border-right: 30px solid white;
  @media ${device.tablet} {
    margin: 5px;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-right: 15px solid white;
  }
`

export default function ImagePreviewModal({
  parentRef,
  open,
  close,
  images,
  index,
}) {
  const classes = useStyles()
  const [activeIndex, setActiveIndex] = useState(index)
  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={open}
      onClose={close}
      aria-labelledby="server-modal-title"
      aria-describedby="server-modal-description"
      className={classes.modal}
      container={() => parentRef.current}
    >
      <Wrapper>
        <ArrowWrapper
          hide={activeIndex == 0}
          onClick={() => setActiveIndex(activeIndex - 1)}
        >
          <ArrowLeft />
        </ArrowWrapper>
        <Container>
          <Image src={images[activeIndex].foto_url} />
          <CloseIconWrapper
            edge="start"
            color="inherit"
            onClick={() => close()}
            aria-label="close"
          >
            <CloseIcon style={{ fontSize: 14 }} />
          </CloseIconWrapper>
        </Container>
        <ArrowWrapper
          onClick={() => setActiveIndex(activeIndex + 1)}
          hide={activeIndex == images.length - 1}
        >
          <ArrowRight />
        </ArrowWrapper>
      </Wrapper>
    </Modal>
  )
}
